import React, { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import Anilink from "gatsby-plugin-transition-link/AniLink";
import styles from "../css/navbar.module.css";
import links from "../constants/links";
import Logo from "../../static/images/logo.png";

const Navbar = () => {
  const [isOpen, setNav] = useState(false);

  const toggleNav = () => {
    setNav(!isOpen);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navCenter}>
        <div className={styles.navHeader}>
          <Anilink fade to="/" data-cy="logo">
            <img className={styles.logoIcon} src={Logo} alt="logo" />
          </Anilink>

          <button
            type="button"
            className={styles.logoBtn}
            onClick={toggleNav}
            data-cy="mobile-button"
          >
            <AiOutlineMenu className={styles.hamburger} size={20} />
          </button>
        </div>
        <ul
          className={
            isOpen
              ? `${styles.navLinks} ${styles.showNav}`
              : `${styles.navLinks}`
          }
        >
          {links.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <Anilink fade to={item.path} data-cy={item.text}>
                {item.text}
              </Anilink>
            </li>
          ))}
          <li>
            <a
              href="https://fulcrumgrowth.zohorecruit.com/jobs/Careers"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jobs
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
