import React from "react";
import Anilink from "gatsby-plugin-transition-link/AniLink";
import links from "../constants/links";
import styles from "../css/footer.module.scss";
// import SocialIcons from "../constants/social-icons";
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        {links.map((item, index) => {
          return (
            <Anilink fade key={index} to={item.path}>
              {item.text}
            </Anilink>
          );
        })}
      </div>
      {/* <div className={styles.icons}>
                {SocialIcons.map((item,index) => {
                    return <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.icon}
                    </a>
                })}
            </div> */}
      <div className={styles.copyright}>
        &copy;
        {new Date().getFullYear()}
        &nbsp;Fulcrum Growth
      </div>
    </footer>
  );
};

export default Footer;
