export default [
  {
    path: "/projects",
    text: "Our Work",
  },
  // {
  //   path: "/blog",
  //   text: "Blog",
  // },
  // {
  //   path: "/about",
  //   text: "About",
  // },
  {
    path: "/contact",
    text: "Contact",
  },
];
