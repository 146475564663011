import React from "react";
import { GiPencilBrush, GiOrganigram, GiChart } from "react-icons/gi";
import { MdDevices } from "react-icons/md";

export default [
  {
    icon: <MdDevices />,
    title: "Software",
    text:
      "Full stack development, automated testing, dev/ops, db design, product strategy, and roadmaps. ",
  },
  {
    icon: <GiPencilBrush />,
    title: "Design & UX",
    text: "Branding, redesigns, usability, user testing, and design systems",
  },
  {
    icon: <GiOrganigram />,
    title: "IT Operations",
    text: "IT strategy, security audits, PCI and HIPAA compliance",
  },
  {
    icon: <GiChart />,
    title: "Advisory",
    text:
      "Technology evaluations, financial modeling, P&L management, and fundraising support",
  },
];
