import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import GatsbyImage from "gatsby-image";

const AboutMe = () => (
  <StaticQuery
    query={graphql`
      query {
        jasKoba: file(relativePath: { eq: "jas-koba.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <AboutMeComponent>
          <ImageWrapper>
            <JasImage
              fluid={data.jasKoba.childImageSharp.fluid}
              alt="JAS and Koba"
            />
          </ImageWrapper>
          <FulcrumText>
            <Title>Our Company</Title>
            <Subtitle>
              Hi!
              <span style={{ marginLeft: `${10}px` }}>
                I&apos;m John Schroeder
              </span>
            </Subtitle>
            <p>
              I bring decades of software development, digital design, product
              management, and IT operations experience as a startup founder,
              corporate executive, and consultant.
            </p>
            <p>
              You&apos;ll work directly with me. I partner with clients remotely
              and expand the team as needed to bring your projects to life. As
              startup veterans, our team can relate to the challenges you and
              your team face.
            </p>
            <p>
              We will make sure your project is executed on time, on budget, and
              with world-class quality. You’ll get all the benefits of hiring a
              full-service development shop, without the hassle or expense.
            </p>
            <Button>
              <Link to="/projects">Learn More</Link>
            </Button>
          </FulcrumText>
        </AboutMeComponent>
      );
    }}
  />
);
export default AboutMe;

const AboutMeComponent = styled.div`
  color: #bec5cc;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  padding: 0 3rem 10rem 3rem;
  max-width: 1600px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  @media (max-width: 660px) {
    padding: 0 1rem 6rem 1rem;
  }
`;

const ImageWrapper = styled.div`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 50%;
  @media (max-width: 959px) {
    width: 100%;
    height: 400px;
  }
`;

const JasImage = styled(GatsbyImage)`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border-style: none;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
  @media (max-width: 959px) {
    object-position: top center;
    border-radius: 0;
    border-radius: 10px 10px 0 0;
  }
`;

const FulcrumText = styled.div`
  line-height: 1.5rem;
  background-color: #191e25;
  font-weight: 400;
  margin: 0;
  box-sizing: border-box;
  width: 50%;
  padding: 80px 60px;
  max-width: 60%;
  background-color: #191e25;
  border-radius: 0 10px 10px 0;
  position: relative;
  z-index: 1;
  @media (max-width: 959px) {
    min-width: 100%;
    padding: 40px;
    border-radius: 0 0 10px 10px;
  }
`;

const Title = styled.div`
  font-family: "Raleway", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 2.9rem;
  font-weight: 700;
  color: var(--mainWhite);
  text-align: left;
  font-size: 2rem;
  margin-bottom: 20px;
`;

const Subtitle = styled.div`
  padding: 0;
  margin: 0 0 0.5rem 0;
  box-sizing: border-box;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--mainWhite);
  text-align: left;
`;

// eslint-disable-next-line react/jsx-props-no-spreading, prettier/prettier
const Button = styled.div`
  text-decoration: none;
  border: 2px solid var(--mainWhite);
  padding: 1rem 2rem;
  text-align: center;
  border-radius: 2rem;
  width: 150px;
  transition: var(--mainTransition);
  margin-top: 2rem;
  a {
    color: var(--mainWhite);
  }
  &:hover {
    background: var(--mainWhite);
    cursor: pointer;
    a {
      color: var(--primaryColor) !important;
    }
  }
`;
