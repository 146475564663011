import React from "react";
import styled from "styled-components";

import Title from "../Title";
import services from "../../constants/services";
import SingleService from "./SingleService";

const Services = () => (
  <ServicesContainer>
    <Title
      title="Turnkey projects & ongoing assistance"
      subtitle="for young, growing companies"
    />
    <hr className="services_separator" />
    <ServicesWrapper>
      {services.map((item, index) => (
        <SingleService
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          icon={item.icon}
          title={item.title}
          text={item.text}
        />
      ))}
    </ServicesWrapper>
  </ServicesContainer>
);

const ServicesContainer = styled.section`
  padding: 5rem 1rem;
  .services_separator {
    width: 20%;
    margin: 4rem auto 0 auto;
  }
`;

const ServicesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 1rem;
  padding: 1rem;

  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 2rem;
    margin-top: 3rem;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export default Services;
