import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Services from "../components/Home/Services";
import StyledHero from "../components/StyledHero";
import SEO from "../components/SEO";
import AboutMe from "../components/AboutMe";
import ProjectWithUs from "../components/Contact/ProjectWithUs";

const Index = ({ data }) => (
  <Layout>
    <SEO title="Home" description={data.site.siteMetadata.description} />
    <StyledHero
      home
      img={data.homeImage.childImageSharp.fluid}
      backgroundColor="0.15"
    >
      <Banner title="Full Stack" info="Technology Services" />
    </StyledHero>
    <Services />
    <AboutMe />
    <ProjectWithUs />
  </Layout>
);

export const getHomeImage = graphql`
  query {
    homeImage: file(relativePath: { eq: "women-rowing.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default Index;
